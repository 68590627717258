import { makeStyles, Theme } from '@material-ui/core/styles';

const gridStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridAutoRows: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  item: {
    padding: theme.spacing(1.5),
  },
}));

export default gridStyles;
