import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppMenuItem, AppMenuItems } from '../../../../hooks';
import DashboardCard from '../DashboardCard';
import gridStyles from './GridView.styles';

const GridView = ({ apps }: AppMenuItems): JSX.Element => {
  const { container, item } = gridStyles();
  const navigate = useNavigate();

  const clickHandler = (path: string) => {
    navigate(path);
  };

  return (
    <div className={container} data-testid="grid-view">
      {apps.map((app: AppMenuItem) => (
        <div className={item} key={app.appName}>
          <DashboardCard app={app} clickHandler={() => clickHandler(app.homeRoute)} />
        </div>
      ))}
    </div>
  );
};
export default GridView;
