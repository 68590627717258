import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../AuthProvider/AuthProvider';

export interface HandleAuthorizationProps {
  permission: string;
  element: JSX.Element;
}

export const HandleAuthorization = ({ permission, element }: HandleAuthorizationProps) => {
  const { appsWithScope } = useAuthContext();
  const isAuthorized =
    appsWithScope.filter((item) => item.appScopePrefix === permission).length > 0;
  return isAuthorized ? element : <Navigate to="/error/403" />;
};

export default HandleAuthorization;
