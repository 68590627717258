import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

interface StyleProps {
  isMobile: boolean;
}

const dashboardStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      width: '100%',
      padding: (props) => (props.isMobile ? '0px 16px' : '0px 60px'),
    },
    title: {
      fontFamily: 'Work Sans',
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 600,
      margin: (props) => (props.isMobile ? '29px 0 24px 0' : '48px 0px'),
    },
  })
);

export default dashboardStyles;
