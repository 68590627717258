import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { AuthProvider } from 'components/AuthProvider';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from './i18n';
import Routes from './Routes';

function App(): React.ReactElement {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-beescare-suite-mfe',
    seed: 'admin-portal-beescare-suite-mfe',
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <AuthProvider>
        <IntlProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </IntlProvider>
      </AuthProvider>
    </StylesProvider>
  );
}

export default App;
