import { appsConfiguration, HandleAuthorization } from 'components';
import { Dashboard } from 'pages';
import React from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { AppsElements } from '../../Routes';

export type RoutesType = {
  authRoutes: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  //noAuthRoutes: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export function useMakeRoutes(): RoutesType {
  const appsRoutes: Array<AppsElements> = appsConfiguration.map((app) => ({
    path: app.baseRoute + '/*',
    element: <HandleAuthorization permission={app.appScopePrefix} element={null} />,
  }));

  const authRoutes = useRoutes([
    {
      path: '/beescare',
      element: <Outlet />,
      children: mainRoutes.concat(appsRoutes),
    },
    {
      path: '*',
      element: <Navigate to="/error/404" />,
    },
  ]);

  return { authRoutes };
}

const mainRoutes: Array<AppsElements> = [
  {
    path: '/beescare',
    element: <Dashboard />,
  },
];

export default useMakeRoutes;
