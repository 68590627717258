import { Grid, List, ListItem } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppMenuItem, AppMenuItems } from '../../../../hooks';
import DashboardCard from '../DashboardCard';
import listStyles from './ListView.styles';

const ListView = ({ apps }: AppMenuItems): JSX.Element => {
  const { root, listItem } = listStyles();
  const navigate = useNavigate();

  const clickHandler = (path: string) => {
    navigate(path);
  };

  return (
    <Grid container spacing={1} data-testid="list-view">
      <List className={root}>
        <Grid item xs={12}>
          {apps.map((app: AppMenuItem) => (
            <ListItem className={listItem} alignItems="center" key={app.appName}>
              <DashboardCard isList app={app} clickHandler={() => clickHandler(app.homeRoute)} />
            </ListItem>
          ))}
        </Grid>
      </List>
    </Grid>
  );
};

export default ListView;
