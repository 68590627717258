import { AppsConfiguration } from 'components';
import { useAppsToList } from 'hooks';
import { useIntl } from 'react-intl';

export type AppMenuItem = {
  appName: string;
  appDescription: string;
  appColor: string;
  homeRoute: string;
};

export type AppMenuItems = {
  apps: Array<AppMenuItem>;
};

const DEFAULT_LANGUAGE = 'en-US';

const LANGUAGE_MATCHER = {
  en: 'en-US',
  pt: 'pt-BR',
  es: 'es-419',
};

function matchLocale(locale: string) {
  const language = locale.split('-')[0];
  const newLocale = LANGUAGE_MATCHER[language] || DEFAULT_LANGUAGE;

  return newLocale;
}

export function useGenerateAppMenuItems(): Array<AppMenuItem> {
  const appsWithScope = useAppsToList();
  const { locale } = useIntl();
  let languages = {
    'en-US': 'en-US',
    'pt-BR': 'pt-BR',
    'es-419': 'es-419',
  };

  return appsWithScope.map((app: AppsConfiguration) => {
    if (!languages[locale]) {
      const newLocale = matchLocale(locale);
      languages = {
        ...languages,
        [locale]: newLocale,
      };
    }

    const language = languages[locale];
    return {
      appName: app.appName[language],
      appDescription: app.appDescription[language],
      homeRoute: app.homeRoute,
      // the appColor is not supposed to change according to the locale. So, we will always get a default locale value to calculate
      appColor: '',
    };
  });
}

export default useGenerateAppMenuItems;
