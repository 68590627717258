import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const listStyles = makeStyles(() =>
  createStyles({
    root: { width: '100%' },
    listItem: {
      marginBottom: '0',
      paddingBottom: '0',
      borderBottom: '1px solid #fafafa',
      paddingTop: '0',
      paddingLeft: '0',
      paddingRight: '0',
      alignItems: 'center',
    },
  })
);
export default listStyles;
