import { IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { List as ListIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AppMenuItems } from '../../../../hooks';
import GridView from '../GridView';
import ListView from '../ListView';
import swapStyles, { CustomizedTooltip } from './SwapView.styles';

const GridIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8zm6 6h-4v-4h4v4z" />
    </SvgIcon>
  );
};

const SwapView = ({ apps }: AppMenuItems): JSX.Element => {
  const [isList, setIsList] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mainWrapper, layoutControl, gridButton, listButton } = swapStyles({ isList, isMobile });
  const intl = useIntl();

  const handleView = (swapState: boolean) => {
    setIsList(swapState);
  };

  return (
    <div className={mainWrapper}>
      <div className={layoutControl}>
        {!isMobile ? (
          <div>
            <CustomizedTooltip
              title={intl.formatMessage({ id: 'Dashboard.tileButtonAlt' })}
              placement="top"
              arrow
            >
              <IconButton
                aria-label="grid"
                onClick={() => handleView(false)}
                className={gridButton}
              >
                <GridIcon />
              </IconButton>
            </CustomizedTooltip>
            <CustomizedTooltip
              title={intl.formatMessage({ id: 'Dashboard.listButtonAlt' })}
              placement="top"
              arrow
            >
              <IconButton aria-label="list" onClick={() => handleView(true)} className={listButton}>
                <ListIcon />
              </IconButton>
            </CustomizedTooltip>
          </div>
        ) : null}
      </div>
      <div className="component-holder">
        {isList && !isMobile ? <ListView apps={apps} /> : <GridView apps={apps} />}
      </div>
    </div>
  );
};

export default SwapView;
