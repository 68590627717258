import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  color: string;
  isList?: boolean;
}
const cardStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: (props) => (props.isList ? '96px' : '133px'),
    display: 'flex',
    alignItems: 'start',
    fontFamily: 'Work Sans',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    padding: 0,
    borderRadius: '0',
    backgroundColor: '#fff',
    boxShadow: (props) =>
      props.isList ? '0px 1px 3px 1px rgb(0 0 0 / 20%)' : '0 2px 4px 0 rgba(0,0,0,0.5)',
    '&:hover': {
      boxShadow: (props) =>
        props.isList
          ? '0px 1px 3px 1px rgb(0 0 0 / 20%)'
          : '0 8px 16px 0 rgba(0,0,0,0.25), 0 2px 4px 0 rgba(0,0,0,0.5)',
      backgroundColor: (props) => (props.isList ? '#EEEEEE' : '#FFFFFF'),
    },
    '&:last-child': { paddingBottom: 0, outline: 0 },
  },
  link: {
    flexGrow: 1,
    textDecoration: ' none',
  },
  card: {
    '&:hover': {
      backgroundColor: (props) => (props.isList ? '#EEEEEE' : '#FFFFFF'),
    },
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    width: '100%',
    alignItems: (props) => (props.isList ? 'center' : ''),
    boxShadow: 'none',
  },
  content: {
    display: 'flex',
    margin: '32px',
    padding: '0px',
    flexGrow: 1,
  },
  title: {
    fontWeight: 600,
    width: (props) => (props.isList ? '30%' : '100%'),
    lineHeight: '25px',
    fontSize: '21px',
    letterSpacing: '0.0075em',
    margin: '0 0 4px 0',
  },
  description: {
    lineHeight: '20px',
    letterSpacing: '0.0075em',
    margin: '0',
    fontSize: '16px',
    fontWeight: 400,
  },
  textContent: {
    display: 'flex',
    alignItems: (props) => (props.isList ? 'center' : 'flex-start'),
    flexGrow: 1,
    flexDirection: (props) => (props.isList ? 'row' : 'column'),
    marginLeft: '15px',
  },
}));

export default cardStyles;
