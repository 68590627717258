import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Just English option available',
  },
  Error500: {
    title: 'Ooops! Something went wrong',
  },
  Error403: {
    title: 'Hmm... Access denied!',
    description: "It looks like you don't have permission to access this page.",
    button: 'Home',
  },
  Error404: {
    title: 'Mmm... Looks like this page is gone',
    description: "It looks like this page wasn't found or doesn't exist.",
    button: 'Home',
  },
  Error503: {
    title: 'Hmm… Service unavailable!',
    description:
      'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
    button: 'Try again',
    infoText: 'If the issue persists, you can <a>report the problem.</a>',
  },
  AvatarMenu: {
    signOut: 'Sign Out',
    account: 'Account',
    getHelp: 'Get Help',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    userProfile: 'User Profile',
  },
  Dashboard: {
    tileButtonAlt: 'Tile View',
    listButtonAlt: 'List View',
    pageTitle: 'BEES Care Suite',
    errorPage: 'Nothing to see here',
  },
  Notification: {
    passwordChanged: 'Password changed successfully!',
  },
};

export default enUS;
