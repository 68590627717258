import { Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppMenuItem } from '../../../../hooks';
import SwapView from '../SwapView';
import dashboardStyles from './DashboardView.styles';

const DashboardView = ({ appsWithScope }: { appsWithScope: Array<AppMenuItem> }): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { title, root } = dashboardStyles({ isMobile });
  const intl = useIntl();

  return (
    <Grid container justifyContent="center" className={root}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom className={title}>
          {intl.formatMessage({ id: 'Dashboard.pageTitle' })}
        </Typography>
        <Divider />
        <SwapView apps={appsWithScope} />
      </Grid>
    </Grid>
  );
};

export default DashboardView;
