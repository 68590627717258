import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
//import BeesLogo from '../../images/BUZZ_Error_AdminHome.svg';
import EmptyViewStyles from './EmptyView.styles';

const EmptyView = (): JSX.Element => {
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { container, logo, text } = EmptyViewStyles({ isMobile });
  return (
    <div>
      <div className={container}>
        {/* <img className={logo} src={BeesLogo} alt="BUZZ_EmptyView_AdminHome" /> */}
        <p className={text} data-testid="emptyPageText">
          {intl.formatMessage({ id: 'Dashboard.errorPage' })}
        </p>
      </div>
    </div>
  );
};

export default EmptyView;
