import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGenerateAppMenuItems } from '../../hooks';
import DashboardView from './components/DashboardView';
import EmptyView from './components/EmptyView';

export function Dashboard() {
  const appsWithScope = useGenerateAppMenuItems();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (appsWithScope.length === 1) {
      navigate(appsWithScope[0].homeRoute);
    }
  }, []);

  if (appsWithScope.length === 1) return null;
  if (!appsWithScope.length) return <EmptyView />;

  return <DashboardView appsWithScope={appsWithScope} />;
}

export default Dashboard;
