import MessageMap from '../i18n.d';

const es419: MessageMap = {
  test: {
    translate: 'Traducción',
  },
  Error500: {
    title: '¡Huy! Algo salió mal',
  },
  Error403: {
    title: 'Hmm... ¡Acceso denegado!',
    description: 'Parece que no tienes permiso para acceder a esta página.',
    button: 'Inicio',
  },
  Error404: {
    title: 'Mmm... Parece que esta página se ha ido',
    description: 'Parece que esta página no se encontró o no existe.',
    button: 'Inicio',
  },
  Error503: {
    title: 'Hmm... ¡Servicio Indisponible!',
    description:
      'Nuestros servidores están temporalmente demasiado ocupados. Puede volver a intentarlo en unos minutos y debería normalizarse.',
    button: 'Intentar Otra Vez',
    infoText: 'Si el problema persiste, puede <a>informarlo aquí.</a>',
  },
  AvatarMenu: {
    signOut: 'Desconectar',
    account: 'Cuenta',
    getHelp: 'Consigue Ayuda',
    privacyPolicy: 'Política de Privacidad',
    termsOfService: 'Términos de Servicio',
    userProfile: 'Perfil del Usuario',
  },
  Dashboard: {
    tileButtonAlt: 'Vista de Mosaico',
    listButtonAlt: 'Vista de Lista',
    pageTitle: 'Página de Inicio',
    errorPage: 'No hay nada que ver aquí',
  },
  Notification: {
    passwordChanged: '¡Contraseña cambiada con éxito!',
  },
};

export default es419;
