import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

interface EmptyViewProps {
  isMobile: boolean;
}

const EmptyViewStyles = makeStyles<Theme, EmptyViewProps>(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: '90px',
      height: '108px',
      marginTop: (props) => (props.isMobile ? '71.5px' : '99.5px'),
    },
    text: { fontFamily: 'Work Sans', fontSize: '16px' },
  })
);

export default EmptyViewStyles;
