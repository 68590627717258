import { Avatar, formatInitials } from '@hexa-ui/components';
import { Button, Card } from '@material-ui/core';
import React from 'react';
import { AppMenuItem } from '../../../../hooks';
import cardStyles from './DashboardCard.styles';

export interface CardProps {
  app: AppMenuItem;
  isList?: boolean;
  clickHandler?: () => void;
}

const DashboardCard = ({ app, isList, clickHandler }: CardProps): React.ReactElement => {
  const { appName, appDescription, appColor } = app;
  const {
    root,
    card,
    title,
    content,
    description: descriptionClass,
    textContent,
  } = cardStyles({
    color: appColor,
    isList,
  });

  return (
    <Button className={root} disableElevation disableRipple onClick={clickHandler}>
      <Card className={card}>
        <div className={content}>
          <Avatar size="xxlarge">{formatInitials(appName)}</Avatar>
          <div className={textContent}>
            <h6 className={title}>{appName.charAt(0).toUpperCase() + appName.slice(1)}</h6>
            <p className={descriptionClass}>{appDescription}</p>
          </div>
        </div>
      </Card>
    </Button>
  );
};

export default DashboardCard;
