import { Theme, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

interface StyleProps {
  isList: boolean;
  isMobile: boolean;
}

const swapStyles = makeStyles<Theme, StyleProps>(() => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Work Sans',
  },
  layoutControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    //margin: (props) => (props.isMobile ? '29px 0 0 0' : '23.5px 0px 27px 0px'),
  },
  gridButton: {
    padding: '5px',
    marginTop: '15px',
    color: '#000',
    //opacity: (props) => (props.isList ? '0.5' : '1'),
  },
  listButton: {
    padding: '5px',
    marginTop: '15px',
    color: '#000',
    //opacity: (props) => (props.isList ? '1' : '0.5'),
  },
}));

export const CustomizedTooltip = withStyles(() => ({
  tooltip: {
    minWidth: '104px',
    fontSize: 14,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: 'Work Sans',
    lineHeight: '23px',
  },
}))(Tooltip);

export default swapStyles;
