import { useAuthenticationService } from 'admin-portal-shared-services';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { AppsConfiguration } from 'components';
import React, { createContext, useContext } from 'react';

export type AuthConfig = {
  hasSession: boolean;
  appsWithScope: Array<AppsConfiguration>;
  userName: string;
  token: string;
  decodedToken?: UserInfoToken;
};

const defaultInitialValue: AuthConfig = {
  hasSession: false,
  appsWithScope: [],
  userName: '',
  token: '',
};

export const appsConfiguration: Array<AppsConfiguration> = [
  {
    id: 'ASSETMANAGEMENT',
    appName: {
      'en-US': 'User Management',
      'es-419': 'User Management',
      'pt-BR': 'User Management',
    },
    appDescription: {
      'en-US': 'Manage BEES Care User',
      'es-419': 'Gestionar Usuarios del BEES Care',
      'pt-BR': 'Gerenciar Usuários do BEES Care',
    },
    appScopePrefix: 'UserManagement',
    baseRoute: 'user-management',
    homeRoute: 'user-management',
    hidden: false,
  },
  {
    id: 'BEESCAREUSERMANAGEMENT',
    appName: {
      'en-US': 'Asset Management',
      'es-419': 'Asset Management',
      'pt-BR': 'Asset Management',
    },
    appDescription: {
      'en-US': 'Manage Assets',
      'es-419': 'Gestionar Assets',
      'pt-BR': 'Gerenciar Assets',
    },
    appScopePrefix: 'AssetManagement',
    baseRoute: 'asset-management',
    homeRoute: 'asset-management',
    hidden: false,
  },
  {
    id: 'BEESCAREL2TICKETS',
    appName: {
      'en-US': 'L2 Tickets',
      'es-419': 'L2 Tickets',
      'pt-BR': 'L2 Tickets',
    },
    appDescription: {
      'en-US': 'Manage L2 Tickets',
      'es-419': 'Gestionar L2 Tickets',
      'pt-BR': 'Gerenciar L2 Tickets',
    },
    appScopePrefix: 'L2Tickets',
    baseRoute: 'l2-tickets',
    homeRoute: 'l2-tickets',
    hidden: false,
  },
  {
    id: 'BEESCARETICKETTOOLS',
    appName: {
      'en-US': 'POC information and orders management',
      'es-419': 'POC information and orders management',
      'pt-BR': 'POC information and orders management',
    },
    appDescription: {
      'en-US': 'Solve customer inquiries faster',
      'es-419': 'Solucione las consultas de los clientes más rápido',
      'pt-BR': 'Resolva as demandas do cliente mais rápido',
    },
    appScopePrefix: 'TicketTools',
    baseRoute: 'ticket-tools',
    homeRoute: 'ticket-tools',
    hidden: false,
  },
];

const init = (): AuthConfig => {
  const authService = useAuthenticationService();
  const auth = authService.getAuthHeader();
  if (auth) {
    const decodedToken = authService.parseJwt();
    const decodedScopes = decodedToken?.scopes || [];
    const midScopes = decodedScopes.map((item) => item.split('.')[1]);
    const appsWithUserAccess = appsConfiguration.filter(
      (item) => midScopes.includes(item.appScopePrefix) || !item.appScopePrefix
    );

    return {
      hasSession: true,
      appsWithScope: appsWithUserAccess,
      userName: decodedToken.name || decodedToken.app || '',
      token: auth,
      decodedToken,
    };
  } else {
    return defaultInitialValue;
  }
};

export const AuthContext = createContext(defaultInitialValue);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const session = init();
  return <AuthContext.Provider value={session}>{children}</AuthContext.Provider>;
};
